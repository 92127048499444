/*=== webfonts ===*/

@font-face {
    font-family: 'Confidel';
    src: url('webfonts/Confidel.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'FreeSans';
    src: url('webfonts/FreeSans.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'FreeSans';
    src: url('webfonts/FreeSansBold.ttf');
    font-weight: 700;
}

/*=== Basic css ===*/
html,
body,
header,
footer,
main,
nav,
section,
div,
menu,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
i,
ol,
ul,
li,
form,
label,
button {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

ol,
ul {
    list-style: none;
}

button:focus,
a:focus,
input:focus,
textarea:focus {
    outline: none;
}

a {
    text-decoration: none;
    display: inline-block;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
}

a:visited,
a:hover,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

body {
    font-family: 'FreeSans';
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    color: #333333;
}

.scrolltotop {
    width: 40px;
    height: 40px;
    line-height: 34px;
    border-radius: 50%;
    background: #091647;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    position: fixed;
    right: 30px;
    bottom: 25px;
    animation: lab_top_up 5s linear infinite;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 2px solid #091647;
    z-index: 8888;
}

.scrolltotop i {
    color: #ffff;
}

@keyframes lab_top_up {
    0% {
        transform: translateY(-15px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-15px);
    }
}

.pluse,
.pluse2 {
    position: relative;
    top: 8px;
    left: 6px;
    z-index: -1;
}

.pluse::before,
.pluse2::before {
    width: 40px;
    height: 40px;
}

.pluse::after,
.pluse::before,
.pluse2::after,
.pluse2::before {
    background: #091647;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
}

.pluse::after,
.pluse2::after {
    width: 30px;
    height: 30px;
    background: transparent;
    margin-left: 30px;
    margin-top: 30px;
}

.pluse::before {
    -webkit-animation: pluse_animate 2.5s infinite linear;
    animation: pluse_animate 2.5s infinite linear;
}

.pluse2::before {
    -webkit-animation: pluse_animate 3s infinite linear;
    animation: pluse_animate 3s infinite linear;
}

@keyframes pluse_animate {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0.3);
        transform: translate(-50%, -50%) scale(0.3);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
    }
}

.container {
    padding-left: 15px;
    padding-right: 15px;
}

/*=== main-wrapper start ===*/

.main-wrapper {
    background-image: url(../../../public/images/main-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/*=== Header area start ===*/

.header-area {
    background-image: url(../../../public/images/header-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 25px 0 40px;
}

.header-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.header-item-inner {
    width: 150px;
}

.header-item-inner ul {
    display: flex;
    align-items: center;
}

.header-item-inner ul li:nth-of-type(1) {
    margin-left: 20px;
}

.header-item-inner ul li img {
    max-width: 100%;
}

.header-item-inner2 {
    width: calc(100% - 150px);
}

.header-item-inner2 ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 100px;
    border-right: 1px solid #707070;
}

.header-item-inner2 ul li a {
    color: #616F9E;
    font-size: 22px;
    font-weight: 700;
    position: relative;
}

.header-item-inner2 ul li a:hover {
    color: #fec222;
}

.header-item-inner2 ul li a img {
    max-width: 100%;
    margin-right: 12px;
}

.header-item-inner2 ul li:nth-of-type(1) a:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FD3333;
    border: 1px solid #982424;
    top: 0;
    right: -17px;
}

/*=== offcanvas start ===*/

.offcanvas-start {
    width: 300px;
    border: none;
    background: #091647;
}

.offcanvas-body {
    padding: 30px 20px;
}

.side-menu-inner {
    position: relative;
}

.side-menu-inner .close {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.side-menu-inner .close span {
    width: 25px;
    height: 3px;
    border-radius: 4px;
    display: block;
    background: #fec222;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
}

.side-menu-inner .close span:nth-of-type(1) {
    transform: rotate(45deg);
    margin-top: 13px;
}

.side-menu-inner .close span:nth-of-type(2) {
    transform: rotate(-45deg);
    margin-top: -3px;
}

.side-menu-inner ul li a {
    color: #616F9E;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
}

.side-menu-inner ul li a:hover {
    color: #fec222;
}

.side-menu-inner ul li a img {
    max-width: 100%;
    margin-right: 12px;
}

/*=== mock area start ===*/

.mock-area {
    padding: 40px 0 55px;
}

.mock-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mock-item-inner ul {
    display: flex;
    align-items: center;
}

.mock-item-inner ul li {
    font-family: 'Confidel';
    width: 175px;
    height: 41px;
    background-image: url(../../../public/images/mock-06.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 7px;
    position: relative;
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}

.mock-item-inner ul li img:nth-of-type(1) {
    position: absolute;
    right: 15px;
    top: -15px;
    cursor: pointer;
}

.mock-item-inner ul li span {
    margin-left: 14px;
}

.mock-item-inner ul li:nth-of-type(1) {
    margin-left: 15px;
}

.mock-item-inner2 h2 {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
}

.mock-item-inner2 h2 img {
    max-width: 100%;
}

.mock-item-inner2 h2 span {
    margin: 0 25px;
}

.mock-item2 {
    background-image: url(../../../public/images/mock-07.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 38px 38px 20px;
    margin: 38px 0 45px;
}

.mock-item2 .row {
    --bs-gutter-x: 20px;
}

.mock-item2-inner {
    background-image: url(../../../public/images/mock-08.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 22px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 144px;
}

.mock-item2-inner2 {
    padding-left: 50px;
}

.mock-item2-inner2 h3 {
    font-size: 24px;
    font-weight: 700;
    color: #0F0F0F;
}

.mock-item2-inner3 {
    width: 102px;
}

.mock-item2-inner3 img {
    width: 100%;
}

.mock-item2-inner4 {
    background-image: url(../../../public/images/mock-10.png);
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.mock-item2-inner4 h2 {
    font-size: 58px;
    font-weight: 700;
    color: #fff;
    line-height: 90%;
}

.mock-item2-inner4 p {
    font-size: 18px;
    font-weight: 400;
    color: #FFDE7B;
}

.mock-item2-inner4 p span {
    color: #7EFF7B;
}

.mock-item3 {
    display: flex;
    flex-wrap: wrap;
}

.mock-item3-inner {
    width: 403px;
    background-image: url(../../../public/images/mock-11.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 30px;
}

.mock-item3-inner2 ul {
    display: flex;
    margin-bottom: 25px;
}

.mock-item3-inner2 ul li {
    width: 50%;
}

.mock-item3-inner2 ul li {
    font-size: 18px;
    font-weight: 700;
    color: #0D0D0D;
    text-align: center;
}

.mock-item3-inner2 ul li span {
    color: #58BE14;
}

.mock-item3-inner2 ul li strong {
    color: #6E6E6E;
}

.mock-item3-inner2 ul li:nth-of-type(1) {
    border-left: 1px solid #707070;
}

.mock-item3-inner3 ul {
    display: flex;
    margin-bottom: 25px;
}

.pokemon-modal {
    /* background-image: url('../../../public/images/bg.png'); */
    background-image: url(../../../public/images/main-bg3.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.pokemon-modal h2,
.pokemon-modal h3 {
    color: white;


}

.mock-item3-inner3 ul li {
    width: 50%;
}

.usertab-active {
    background-image: url(../../../public/images/mock-13.png);
    color: #070707;
}

.usertab {
    background-image: url(../../../public/images/mock-12.png);
    color: #FFFFFF;

}

.mock-item3-inner3 ul li a {
    /* background-image: url(../../../public/images/mock-12.png); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    /* color: #FFFFFF; */
}


.mock-item3-inner3 ul li a:hover {
    opacity: .8;
}

.mock-item3-inner4 {
    height: 340px;
    overflow-y: scroll;
    direction: ltr;
    padding-right: 24px;
}

.mock-item3-inner4::-webkit-scrollbar-track {
    border-radius: 10px;
    width: 6px;
    background-color: #939393;
}

.mock-item3-inner4::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    background-image: url(../../../public/images/scroll-icon.png);
    background-repeat: no-repeat;
    background-position: center;
}

.mock-item3-inner4::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 6px;
    background-image: url(../../../public/images/scroll-icon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}

.mock-item3-inner5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    background-color: #0c397d;
    border-radius: 30px;
    margin-bottom: 14px;
}

.mock-item3-inner5 h2 {
    font-size: 16px;
    font-weight: 400;
    color: #9B9B9B;
}

.mock-item3-inner5 h2 strong {
    font-weight: 700;
    color: #70FF59;
}

.mock-item3-inner5 p {
    font-size: 16px;
    font-weight: 400;
    color: #FFFBFB;
    display: flex;
    align-items: center;
}

.mock-item3-inner5 p img {
    max-width: 100%;
    margin-right: 10px;
}

.mock-item3-inner8 {
    background-color: #4b2759;
}

.mock-item3-inner9 {
    width: calc(100% - 403px);
    padding-right: 22px;
}

.mock-item3-inner10 {
    background-image: url(../../../public/images/mock-16.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 30px 30px 16px 40px;
}

.mock-item3-inner10 .mock-item3-inner4 {
    height: 400px;
    padding-right: 45px;
}

.mock-item3-inner11 {
    background-image: url(../../../public/images/mock-17.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 8px 85px 9px 16px;
    min-height: 70px;
    margin-bottom: 22px;
    text-align: right;
    position: relative;
}

.mock-item3-inner11>img {
    max-width: 100%;
    position: absolute;
    top: 0px;
    right: 9px;
}

.mock-item3-inner11 h2 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 20px;
}

.mock-item3-inner11 p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-shadow: 0px 0px 5px #0f1012;
}

.mock-item3-inner12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 35px;
    background-color: #222c52;
    border-radius: 30px;
    margin-bottom: 22px;
}

.mock-item3-inner12 h2 {
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFE;
}

.mock-item3-inner12 h2 strong {
    font-weight: 400;
    color: #F4BC26;
}

.mock-item3-inner12 h2 span {
    text-decoration: underline;
}

.mock-item3-inner12 p {
    font-size: 18px;
    font-weight: 400;
    color: #F45226;
}

.mock-item3-inner12 p span {
    color: #60F426;
    margin-left: 16px;
}

.mock-item3-inner13 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;
    position: sticky;
    bottom: 0px;
}

.chat-box img {
    height: 50px;
    width: 50px !important;
}

.block-box {
    align-items: center;
    height: 100px;
}


.notfound {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.mock-item3-inner13 img {
    max-width: 100%;
}

.mock-item3-inner13 p {
    font-size: 16px;
    font-weight: 400;
    color: #FDFEFF;
}

/*=== footer area start ===*/

.footer-area {
    background-image: url(../../../public/images/footer-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 90px 0 75px;
    /* position: relative; */
    width: 100%;
    /* bottom: 50px; */
}

.footer-item {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
}

.footer-item-inner {
    padding-left: 70px;
    padding-bottom: 16px;
    padding-top: 4px;
    border-left: 1px solid #707070;
}

.footer-item h2 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 22px;
    direction: ltr;
}

.footer-item-inner h2 {
    margin-bottom: 25px;
}

.footer-item-inner p {
    font-size: 18px;
    font-weight: 400;
    color: #37426F;
    letter-spacing: -.7px;
    direction: ltr;
}

.footer-item img {
    max-width: 100%;
}

.footer-item-inner2 ul {
    display: flex;
    align-items: center;
}

.footer-item-inner2 ul li a {
    margin-left: 20px;
    overflow: hidden;
    border-radius: 50%;
}

.footer-item-inner2 ul li a img {
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
}

.footer-item-inner2 ul li:last-of-type a {
    margin-left: 0;
}

.footer-item-inner2 ul li a img:hover {
    transform: scale(1.1);
}

/*=== banner area start ===*/

.fixed-icon {
    position: fixed;
    top: 0;
    right: 130px;
    z-index: 8888;
}

.fixed-icon ul {
    display: flex;
    gap: 10px;
}

.fixed-icon ul li a img {
    max-width: 100%;
    height: 50px;

}

.fixed-icon ul li:nth-of-type(1) {
    margin-left: 10px;
}

.main-wrapper2 {
    background-image: url(../../../public/images/main-bg2.png);
    background-attachment: fixed;
}

.banner-area {
    min-height: 100vh;
    padding: 70px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-item-inner img {
    max-width: 100%;
}

.banner-item-inner2 {
    padding: 60px 0 90px;
}

.banner-item-inner2 ul {
    padding: 0px;
}

.banner-item-inner2 ul li a {
    font-size: 36px;
    font-weight: 700;
    color: #FFFCF3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 378px;
    height: 73px;
    background-image: url(../../../public/images/banner-01.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    margin: 0 auto 20px;
    text-decoration: none;
    cursor: pointer;
}

.banner-item-inner2 ul li:nth-of-type(2) a {
    background-image: url(../../../public/images/banner-02.png);
    justify-content: space-between;
    height: 73px;
    padding-left: 50px;
    padding-right: 12px;
    font-size: 30px;
    font-weight: 400;
    cursor: pointer;

    color: #D7D7D7;
}

.banner-item-inner2 ul li:nth-of-type(2) a span {
    max-width: 90%;
}

.banner-item-inner4 {
    display: inline-block;
    cursor: pointer;
}

.banner-item-inner4 img {
    max-width: 100%;
    display: block;
    margin: 0 auto 10px;
}

.banner-item-inner4 span {
    font-size: 18px;
    font-weight: 400;
    color: #FCF9F9;
}

/*=== quick area start ===*/

.quick-area {
    background-image: url(../../../public/images/quick-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 69px 0 50px;
    direction: ltr;
}

.quick-item {
    max-width: 1154px;
    margin: 0 auto;
}

.quick-item h2 {
    font-size: 50px;
    font-weight: 700;
    color: #4F4F4F;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
}

.quick-item h2 img {
    max-width: 100%;
    margin-right: 15px;
}

.quick-item-inner ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9.5px;
}

.quick-item-inner ul li {
    width: 33.333%;
    padding: 0 9.5px;
}

.quick-item-inner ul li a {
    font-size: 32px;
    font-weight: 700;
    color: #E4E3E3;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    background-image: url(../../../public/images/quick-02.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;
    z-index: 8;
    margin-bottom: 35px;
    text-decoration: none;

}

.quick-item-inner ul li a img {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 5px;
}

.quick-item-inner ul li:nth-of-type(2) a {
    background-image: url(../../../public/images/quick-03.png);
}

.quick-item-inner ul li:nth-of-type(3) a {
    background-image: url(../../../public/images/quick-04.png);
}

.quick-item-inner ul li:nth-of-type(2) a img {
    right: 10px;
}

.quick-item-inner ul li:nth-of-type(3) a img {
    right: 38px;
}

.quick-item-inner2 {
    padding-top: 95px;
}

.quick-item-inner3 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px;
}

.quick-item-inner4 {
    width: calc(50% - 46px);
    padding: 0 7.5px;
}

.quick-item-inner5 {
    position: relative;
    margin-bottom: 30px;
}

.quick-item-inner5 img {
    width: 100%;
}

.quick-item-inner5 span {
    font-size: 18px;
    font-weight: 700;
    color: #1F1701;
    width: 98px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 19px;
    right: 22px;
    background-color: #FFCA39;
    border-radius: 20px;
}

.quick-item-inner5 h3 {
    font-size: 36px;
    font-weight: 400;
    color: #FFFFFF;
    position: absolute;
    left: 35px;
    bottom: 26px;
    display: flex;
    align-items: flex-end;
}

.quick-item-inner5 h3 small {
    font-size: 18px;
    color: #ACACAC;
    transform: translateY(-5px);
}

.quick-item-inner6 {
    width: 92px;
    margin-bottom: 30px;
    padding: 0 7.5px
}

.quick-item-inner6 img {
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    height: 96%;
}

/*=== notice area start ===*/

.notice-area {
    background-image: url(../../../public/images/notice-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 60px 0 0px;
    direction: ltr;
    overflow: hidden;
}

.notice-item {
    max-width: 1154px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.notice-item-inner {
    width: calc(100% - 519px);
    padding-top: 50px;
    position: relative;
    z-index: 9;
    margin-bottom: 30px;
}

.notice-item-inner h2 {
    font-size: 60px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: -4px;
    margin-bottom: 65px;
}

.notice-item-inner h2 img {
    max-width: 100%;
    margin-right: 15px;
}

.notice-item-inner p {
    font-size: 30px;
    font-weight: 400;
    color: #8D8D8D;
    margin-left: 65px;
}

.notice-item-inner2 {
    width: 519px;
    margin-bottom: 110px;
}

.notice-item-inner2 img {
    width: 100%;
}

.notice-item-inner3 h2 {
    font-size: 60px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: -4px;
    margin-bottom: 65px;
}

.notice-item-inner3 {
    padding-top: 36px;
    padding-left: 95px;
}

.notice-item-inner3 p {
    font-size: 30px;
    font-weight: 400;
    color: #8D8D8D;
    margin-left: 65px;
}

.notice-item-inner4 {
    position: absolute;
    top: -40px;
    left: -50px;
    z-index: -1;
    cursor: none;
    user-select: none;
}

.second-h3 {
    left: -47px !important;
    left: 23px !important;
    top: -91px;

}

.notice-item-inner4 h3 {
    font-size: 127px;
    font-weight: 700;
    color: #111111;
}

.notice-item-inner3 .notice-item-inner4 {
    top: -50px;
    left: 40px;
}

/*=== footer area2 start ===*/

.footer-area2 {
    background-image: url(../../../public/images/footer-bg2.png);
    padding: 90px 0;
}

.sub-layout-child {
    min-height: 80vh;
}

.pb-50 {
    padding-bottom: 50px;
}

.bg-body-header {
    background: #0a1544;
}

.footer-item2 {
    max-width: 1158px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    direction: ltr;
}

.footer-item2-inner {
    display: flex;
    align-items: center;
}

.footer-item2-inner h2 {
    font-size: 36px;
    font-weight: 700;
    color: #0B0B0B;
    margin-right: 35px;
}

.footer-item2-inner ul {
    display: flex;
    align-items: center;
    padding-right: 30px;
}

.footer-item2-inner ul li a {
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
}

.footer-item2-inner ul li a img {
    max-width: 100%;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
}

.footer-item2-inner ul li a img:hover {
    transform: scale(1.1);
}

.footer-item2-inner2 {
    padding-left: 45px;
    border-left: 1px solid #707070;
}

.footer-item2-inner2 p {
    font-size: 18px;
    font-weight: 400;
    color: #0B0B0B;
    letter-spacing: -1px;
}

/*=== register page start ===*/

.main-wrapper3 {
    background-image: url(../../../public/images/main-bg3.png);
    background-position: center;
    ;
    background-repeat: no-repeat;
    background-size: cover;
}

.validation-box {
    min-height: 90px;
}

.register-area {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 70px 0;
}

.register-item form {
    max-width: 830px;
    margin: 0 auto;
    direction: ltr;
}

.register-item-inner {
    position: relative;
    margin-bottom: 40px;
}

.register-item-inner h2 {
    font-size: 72px;
    font-weight: 700;
    color: #FCF9F9;
    text-align: center;
}

.register-item-inner img {
    max-width: 100%;
    cursor: pointer;
}

.register-item-inner>img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.register-item-inner2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
}

.register-item-inner3 {
    width: 50%;
    padding: 0 7px;
}

.bg-theme {
    background-color: #091647 !important;
}

.bg-light-theme {
    background-color: #1e2953 !important;
}

.register-item-inner4 {
    position: relative;
    margin-bottom: 20px;
}

.region .form-select {
    background-color: #290704 !important;
}

.pokemon-tootltip {
    background-color: #000 !important;
    width: 500px;
}

.accordion-badge button {
    background-color: #091647 !important;
    border: none;
    color: white !important;
    justify-content: center;
}

.onlineTrainer-names {
    padding: 10px;
    background-color: #290704;
    border-radius: 16px;
    cursor: pointer;
}

.onlineTrainer-names img {
    left: 5px !important;
}

.tf-check input {
    height: 20px;
    width: 20px;
}
.border-bottom-theme{
    
}

.small-button {
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
    background-color: rgba(128, 128, 128, 0.225);
}

.challenge-button {
    height: 42px !important;
    width: 139px !important;
    font-size: 16px !important;
}

.fountain-button {
    height: 42px !important;
    width: 179px !important;
    font-size: 16px !important;
}

.block-button {
    height: 30px !important;
    width: 100px !important;
    font-size: 12px !important;
}

.change-password input {
    background-color: #0C1945 !important;
    border-radius: 35px;
    width: 100%;
    height: 60px;
    border: none;
    padding-left: 65px;
    padding-right: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #9E8F61 !important;
    text-align: left;
}

.lavel-radio input {
    width: 20px;
    height: 20px;
}

.settings-anchor {
    text-decoration: none;
}

.change-password .validation-box img {
    left: 25px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.change-password input::placeholder {
    color: gray;
}

.unblock-button {
    height: 25px !important;
    width: 80px !important;
    font-size: 12px !important;
}

.calculator-button {
    height: 50px !important;
    width: 250px !important;
    font-size: 12px !important;
}

.b--35 {
    bottom: -35px !important;
}

.chat-input {
    position: sticky;
    top: 0px;
    gap: 10px;
    padding: 20px;
    z-index: 99;
}

.table-sticky-header {
    position: sticky;
    top: 0px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.register-item-inner4 input {
    /* background-image: url(../../../public/images/register-04.png) !important; */
    border-radius: 35px;
    background-color: #290704 !important;
    /* background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; */
    width: 100%;
    height: 60px;
    border: none;
    /* background-color: transparent !important; */
    padding-left: 65px;
    padding-right: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #9E8F61 !important;
    text-align: left;
}

.register-item-inner4>img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
}

.register-item-inner5 {
    padding-bottom: 30px;
}

.register-item-inner5 label {
    font-size: 16px;
    font-weight: 400;
    color: #FFE477;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.badges-img {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.register-item-inner5 label span {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    background-color: #150b00;
    border: 1px solid #985627;
}

.register-item-inner5 label span img {
    max-width: 100%;
    opacity: 0;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
}

.register-item-inner5 input {
    display: none;
}

.register-item-inner5 input:checked+label span img {
    opacity: 1;
}

.register-item-inner6 button {
    font-size: 36px;
    font-weight: 700;
    color: #FCF9F9;
    width: 429px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-image: url(../../../public/images/register-05.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: transparent;
    margin: 0 auto;
}

.header-main li{
    display: flex;
    gap: 7px;
    cursor: pointer;
    color: #cfcfcf;
}
.register-item-inner6 .register-button {
    font-size: 36px;
    font-weight: 700;
    color: #FCF9F9;
    width: 429px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-image: url(../../../public/images/banner-02.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: transparent;
    margin: 0 auto;
    padding: 10px 10px;
    position: relative;
}
.mochila-items{
    background-color: #000827;
    border: 2px solid rgba(255, 255, 255, 0.563);
    border-radius: 5px;
    padding: 5px;
}
.register-button img {
    position: absolute;
    right: 20px;
}

.register-button span {
    width: 84%;
}

/*=== login page start ===*/

.register-item-inner7 {
    max-width: 404px;
    margin: 0 auto;
}

.register-item-inner8 {
    padding: 10px 0 30px;
    text-align: center;
}

.register-item-inner8 a {
    font-size: 18px;
    font-weight: 400;
    color: #FFE477;
}

.register-item-inner8 a:hover {
    color: #fec222;
}

.pokemon-gifs {
    /* background-image: url(../../../public/images/item2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%; */
    text-align: center;
}

/*=== character page start ===*/

.character-area {
    background-image: url(../../../public/images/character-bg.png);
    background-repeat: no-repeat;
    background-size: 273px;
    background-position: center 140px;
    padding: 101px 0 20px;
}

.character-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.character-item-inner .mock-item-inner {
    margin-bottom: 30px;
}

.character-item-inner2 ul {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.character-item-inner2 ul li {
    position: relative;
    margin-left: 14px;
    cursor: pointer;
}

.character-item-inner2 ul li img {
    max-width: 85%;
}

.character-item-inner2 ul li span {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    position: absolute;
    bottom: 6px;
    min-width: 90px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    text-shadow: 1px 1px 1px #000;
    cursor: text;
}

.character-item-inner2 ul li:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #982424;
    background-color: #FD3333;
    top: 6px;
    right: 12px;
}

.character-item-inner2 ul li:first-of-type:after {
    display: none;
}

.character-item-inner3 {
    display: inline-block;
}

.character-item-inner3 p {
    font-family: 'Confidel';
    font-size: 24px;
    font-weight: 400;
    color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
}

.character-item-inner3 span {
    text-shadow: 0px 0px 5px #0f1012;
}

.character-item-inner3 small {
    font-family: 'FreeSans';
    font-size: 14px;
    font-weight: 700;
    color: #F8F8F8;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #19a619;
    margin: 0 12px;
}

.character-item-inner3 small:nth-of-type(1) {
    background-color: #A61919;
}

.character-item-inner3 a {
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 256px;
    height: 54px;
    background-image: url(../../../public/images/character-05.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.character-item-inner5 {
    display: flex;
    align-items: center;
}

.character-item-inner6 {
    width: 318px;
    padding-left: 18px;
}

.character-item-inner7 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.character-item-inner7 h3 {
    font-size: 16px;
    font-weight: 700;
    color: #F6F6F6;
    text-shadow: 0px 0px 5px #0f1012;
    display: flex;
    align-items: center;
}

.character-item-inner7 h3 img {
    max-width: 100%;
    margin-right: 7px;
}

.character-item-inner7 p {
    font-size: 16px;
    font-weight: 700;
    color: #F6F6F6;
    text-shadow: 0px 0px 5px #0f1012;
}

.character-item-inner7 p span {
    color: #FFCD21;
    margin-left: 4px;
}

.character-item-inner8 {
    background-image: url(../../../public/images/character-08.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;
    height: 31px;
}

.character-item-inner8 span {
    position: absolute;
    background-image: url(../../../public/images/character-09.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    top: 1px;
    left: 1px;
    width: 64%;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 8px;
}

.character-item-inner8 span small {
    font-size: 14px;
    font-weight: 400;
    color: #F6F6F6;
    text-shadow: 0px 0px 5px #0f1012;
}

.character-item-inner8 p {
    font-size: 14px;
    font-weight: 400;
    color: #F6F6F6;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
}

.character-item-inner10 {
    display: flex;
    align-items: center;
    padding-top: 27px;
    justify-content: flex-end;
}

.character-item-inner11 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: url(../../../public/images/character-10.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 191px;
    height: 79px;
}

.character-item-inner11 div:nth-of-type(2) {
    width: 92px;
    text-align: left;
    padding-left: 12px;
}

.character-item-inner11 img {
    max-width: 100%;
}

.character-item-inner11 h2 {
    font-size: 22px;
    font-weight: 700;
    color: #292929;
    text-align: left;
}

.character-item-inner11 h2 span {
    font-size: 16px;
    color: #D71E1E;
    display: block;
}

.character-item-inner12 {
    margin-right: 10px;
}

.character-item-inner12 div:nth-of-type(2) {
    padding-left: 5px;
}

.character-item-inner13 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: url(../../../public/images/character-13.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 301px;
    height: 66px;
    margin: 22px 0;
    margin-right: auto;
}

.character-item-inner13 h2 {
    font-size: 18px;
    font-weight: 700;
    color: #F6F6F6;
    text-align: left;
}

.character-item-inner13 h2 span {
    color: #FFC851;
    display: block;
}

.character-item-inner13 div:nth-of-type(2) {
    width: 79px;
    padding-left: 1px;
    padding-right: 15px;
}

.character-item-inner13 div:nth-of-type(2) img {
    max-width: 100%;
}

.character-item-inner14 {
    text-align: left;
}

.character-item-inner15 {
    background-image: url(../../../public/images/character-15.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: inline-block;
    padding: 13px 37px;
}

.character-item-inner15 p {
    font-size: 16px;
    font-weight: 400;
    color: #FBFAF7;
    direction: ltr;
}

.character-item-inner15 p strong {
    font-weight: 400;
    color: #FFD71E;
}

.character-item-inner15 p span {
    text-decoration: underline;
    margin-right: 3px;
}

.character-item2 {
    direction: ltr;
}

.character-item2 h2 {
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 20px;
    text-shadow: 0px 0px 5px #0f1012;
}

.character-item2-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9px;
}

.character-item2-inner2 {
    width: 16.666%;
    padding: 0 9px;
}

.character-item2-inner3 {
    position: relative;
    margin-bottom: 20px;
    border-radius: 13px;
    background: #7d7d7d8f;
}

.character-item2-inner3>img {
    width: auto;
    height: 104px
}

.character-item2-inner4 {
    position: absolute;
    top: 0;
    right: 0;
}

.character-item2-inner4 img {
    max-width: 100%;
}

.character-item2-inner5 {
    /* position: absolute; */
    left: 0;
    bottom: 0;
    padding-top: 13px;
    width: 100%;
    text-align: center;
}

.character-item2-inner5 p {
    font-size: 15px;
    font-weight: 700;
    color: #F6F6F6;
    padding: 8px;
    background: black;
    border-radius: 0px 0px 13px 13px;
}

.character-item2-inner5 p span {
    font-weight: 400;
    color: #41E02C;
}

.character-item2-inner6 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.character-item2-inner6 input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.error-text {
    color: red;
}

.language>div {
    position: fixed;
    display: flex;
    gap: 20px;
    width: 100%;
    z-index: 1000000;
}

.language span {
    cursor: pointer;
    color: rgb(193, 193, 193);
}

.pk-description {
    width: 50%;
    position: relative;
}

.main-wrapper p {
    margin: 0;
}

.side-menu ul li a {
    color: white;
    font-size: 20px;
    gap: 10px;
}

.custom-offcanvas-dark button {
    color: white;

}

.notification h5 a {
    color: #091647;

}

.custom-offcanvas-dark {
    background-color: #000827 !important
}

.card-header {
    border-bottom: 2px solid #1e2953 !important;
}

.header-main {
    background-image: url(../../../public/images/header-bg.png);
    height: 60px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* position: absolute; */
    padding-top: 12px;
    z-index: 1;
    position: relative;
}

.child {
    position: relative;
    /* top:-25px; */
    z-index: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.dropdown-toggle::after {
    background-image: url(../../../public/images/header-02.png);
}

.home-header {
    position: absolute;
    top: 0px;
    width: 100%;
}

.home-footer {
    position: absolute;
    bottom: -28%;
    width: 100%;
}

.poke-profile-badges {
    padding: 7px !important;
    font-size: 12px !important;
    min-width: 70px;
}

.elipse {
    background: #0a184f;
    border-radius: 50%;
    padding: 4px;
}

.fishing-road {
    width: 113px;
    background: #1e2953;
    border-radius: 5px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fishing-road img {
    width: 60%;
}

.fishing-road input {
    height: 20px;
    width: 20px;
}

.table-theme th,
.table-theme td {
    background-color: #1e2953 !important;
    /* --bs-table-bg-type: #1e2953 !important; */
    border: 1px solid #000 !important;
    color: white !important;
    /* border-width: 0px !important; */
}

.table-theme tr:nth-child(even) td,
.table-theme tr:nth-child(even) td {
    background-color: #0a184f !important;
    --bs-table-bg-typ: #0a184f !important;

}
.travel-title{
    position: absolute;
    z-index: 1;
    font-size: 123px;
    z-index: 1;
}
.gym-trainer{
    height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.description{
    text-align: start;
    padding: 10px 20px;
}
.locked-trainer{
    height: 100px;
    position: absolute;
}
.locked{
    filter: brightness(0%);
}
.description p{
    font-size: 13px;
}
.gym-title{
    position: absolute;
    bottom: 20px;
    width: 100%;
    background-color: #1e295340;
}
.gym-trainer .trainer{
    height: 150px;
}

.challange-pk {
    background: url(../../../public/images/register-02.png);
    width: 34px;
    height: 34px;
    background-size: cover;
}

.header-theme {
    padding: 5px;
    background-color: #1e2953;
}

.green {
    background-color: #34da0f;
    color: white;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.red {
    background-color: #f72533;
    color: white;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

}



.border-none-theme th,
.border-none-theme td {
    border: 0px !important;
}

.btn-type {
    padding-top: -3px;
    color: #eee;
    background-repeat: no-repeat;
    height: 57px;
    width: 154px;
    margin: 3px 5px;
    border: 0;
    opacity: .8;
    text-shadow: #000 1px -1px 2px, #000 -1px 1px 2px, #000 1px 1px 2px, #000 -1px -1px 2px;
    cursor: pointer;
    border-radius: 4px;
    z-index: 10;
}

.btn-type:hover {
    opacity: 1;
    box-shadow: 1px 1px 1px 1px gray;
}

.pl-style input::placeholder {
    color: #a0a0a0;
    /* Change to your desired color */
    opacity: 1;
    /* Adjust opacity if needed */
}

.img-round {
    background-color: #091647;
    padding: 5px;
    border-radius: 50%;
    overflow: hidden;
}

.vertical-align-middle {
    vertical-align: middle;
}

.normal-li li {
    list-style: disc;
}

.specialist-button {
    font-size: 16px !important;
    width: 69px !important;
    height: 28px !important;
}

.pk-bg {
    height: 215px;
    margin-bottom: 6px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.progress {
    background-image: url(../../../public/images/progress.gif);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    height: 100%;
    max-width: 100%;
    transition: width 1s;
}

.potions-button {
    font-size: 12px !important;
    width: 69px !important;
    height: 28px !important;
}

.general-box {
    background-color: #023177;
    padding: 20px;
    border-radius: 10px;
}

.custom-popover {
    max-width: 500px !important;
    background-color: #212529 !important;
    /* Allow popover to stretch fully */
    width: 100%;
    /* Default width to take full width of target */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 0px;
}

.pokeom-judge {
    font-size: 16px;
}

.pk-link:hover {
    text-decoration: underline !important;
}

.Normalt {
    background: #8a8a59;
    background-image: -webkit-linear-gradient(#a8a878, #8a8a59);
    background-image: linear-gradient(#a8a878, #8a8a59);
    border-color: #79794e;
}

.tipo {
    display: inline-block;
    width: 31px;
    height: 31px;
    line-height: 32px;
    border: 1px solid #aaa;
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-shadow: 1px 1px 1px #333;
    text-transform: uppercase;
    border-radius: 4px;
    display: inline-block;
    margin: 1px;
    border: 1px solid #577599;
    box-shadow: 0 2px 0 0 #0f1a2a;
    overflow: hidden;
}

.Firet {
    background: #f08030;
    background-image: -webkit-linear-gradient(#f08030, #dd6610);
    background-image: linear-gradient(#f08030, #dd6610);
    border-color: #b4530d;
}

.Watert {
    background: #6890f0;
    background-image: -webkit-linear-gradient(#6890f0, #386ceb);
    background-image: linear-gradient(#6890f0, #386ceb);
    border-color: #1753e3;
}

.Electrict {
    background: #f8d030;
    background-image: -webkit-linear-gradient(#f8d030, #f0c108);
    background-image: linear-gradient(#f8d030, #f0c108);
    border-color: #c19b07;
}

.Grasst {
    background: #78c850;
    background-image: -webkit-linear-gradient(#78c850, #5ca935);
    background-image: linear-gradient(#78c850, #5ca935);
    border-color: #4a892b;
}

.Icet {
    background: #98d8d8;
    background-image: -webkit-linear-gradient(#98d8d8, #69c6c6);
    background-image: linear-gradient(#98d8d8, #69c6c6);
    border-color: #45b6b6;
}

.Fightingt {
    background: #c03028;
    background-image: -webkit-linear-gradient(#c03028, #9d2721);
    background-image: linear-gradient(#c03028, #9d2721);
    border-color: #82211b;
}

.Poisont {
    background: #a040a0;
    background-image: -webkit-linear-gradient(#a040a0, #803380);
    background-image: linear-gradient(#a040a0, #803380);
    border-color: #662966;
}

.Groundt {
    background: #e0c068;
    background-image: -webkit-linear-gradient(#e0c068, #d4a82f);
    background-image: linear-gradient(#e0c068, #d4a82f);
    border-color: #aa8623;
}

.Flyingt {
    background: #a890f0;
    background-image: -webkit-linear-gradient(#a890f0, #9180c4);
    background-image: linear-gradient(#a890f0, #9180c4);
    border-color: #7762b6
}

::-webkit-scrollbar {
    width: 7px;
    /* Change width as needed */
    height: 7px;
    /* Change width as needed */
}

/* Background of the entire scrollbar */
::-webkit-scrollbar-track {
    background: transparent;
    /* Track color */
    border-radius: 10px;
    /* Rounded edges */
}

/* Color and style of the scroll thumb */
::-webkit-scrollbar-thumb {
    background: #888;
    /* Thumb color */
    border-radius: 10px;
    /* Rounded edges */
}

/* Thumb hover effect */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Darker color when hovering */
}



.type-icon:active,
.type-icon:focus,
.type-icon:hover {
    color: #fff;
    text-decoration: none;
}

.type-icon {
    background: #ccc;
    /* background: -webkit-linear-gradient(#ddd,#bbb) #ccc; */
    /* background: linear-gradient(#ddd,#bbb) #ccc; */
    /* border: 1px solid #aaa; */
    padding: 5px 0px;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-shadow: 1px 1px 1px #333;
    text-transform: uppercase;
    display: inline-block;
    width: 60px;
    line-height: 1;
    margin: 1px 0;
}

.type-normal {
    background: #8a8a59;
    background-image: -webkit-linear-gradient(#a8a878, #8a8a59);
    background-image: linear-gradient(#a8a878, #8a8a59);
    border-color: #79794e;
}

.type-fire {
    background: #f08030;
    background-image: -webkit-linear-gradient(#f08030, #dd6610);
    background-image: linear-gradient(#f08030, #dd6610);
    border-color: #b4530d;
}

.type-water {
    background: #6890f0;
    background-image: -webkit-linear-gradient(#6890f0, #386ceb);
    background-image: linear-gradient(#6890f0, #386ceb);
    border-color: #1753e3;
}

.type-electric {
    background: #f8d030;
    background-image: -webkit-linear-gradient(#f8d030, #f0c108);
    background-image: linear-gradient(#f8d030, #f0c108);
    border-color: #c19b07;
}

.type-grass {
    background: #78c850;
    background-image: -webkit-linear-gradient(#78c850, #5ca935);
    background-image: linear-gradient(#78c850, #5ca935);
    border-color: #4a892b;
}

.type-ice {
    background: #98d8d8;
    background-image: -webkit-linear-gradient(#98d8d8, #69c6c6);
    background-image: linear-gradient(#98d8d8, #69c6c6);
    border-color: #45b6b6;
}

.type-fighting {
    background: #c03028;
    background-image: -webkit-linear-gradient(#c03028, #9d2721);
    background-image: linear-gradient(#c03028, #9d2721);
    border-color: #82211b;
}

.type-poison {
    background: #a040a0;
    background-image: -webkit-linear-gradient(#a040a0, #803380);
    background-image: linear-gradient(#a040a0, #803380);
    border-color: #662966;
}

.type-ground {
    background: #e0c068;
    background-image: -webkit-linear-gradient(#e0c068, #d4a82f);
    background-image: linear-gradient(#e0c068, #d4a82f);
    border-color: #aa8623;
}

.type-flying {
    background: #a890f0;
    background-image: -webkit-linear-gradient(#a890f0, #9180c4);
    background-image: linear-gradient(#a890f0, #9180c4);
    border-color: #7762b6;
}

.type-psychic {
    background: #f85888;
    background-image: -webkit-linear-gradient(#f85888, #f61c5d);
    background-image: linear-gradient(#f85888, #f61c5d);
    border-color: #d60945;
}

.type-bug {
    background: #a8b820;
    background-image: -webkit-linear-gradient(#a8b820, #8d9a1b);
    background-image: linear-gradient(#a8b820, #8d9a1b);
    border-color: #616b13;
}

.type-rock {
    background: #b8a038;
    background-image: -webkit-linear-gradient(#b8a038, #93802d);
    background-image: linear-gradient(#b8a038, #93802d);
    border-color: #746523;
}

.type-ghost {
    background: #705898;
    background-image: -webkit-linear-gradient(#705898, #554374);
    background-image: linear-gradient(#705898, #554374);
    border-color: #413359;
}

.type-dragon {
    background: #7038f8;
    background-image: -webkit-linear-gradient(#7038f8, #4c08ef);
    background-image: linear-gradient(#7038f8, #4c08ef);
    border-color: #3d07c0;
}

.type-steel {
    background: #b8b8d0;
    background-image: -webkit-linear-gradient(#b8b8d0, #9797ba);
    background-image: linear-gradient(#b8b8d0, #9797ba);
    border-color: #7a7aa7;
}

.type-dark {
    background: #705848;
    background-image: -webkit-linear-gradient(#705848, #513f34);
    background-image: linear-gradient(#705848, #513f34);
    border-color: #362a23;
}

.type-fairy {
    background: #e898e8;
    background-image: -webkit-linear-gradient(#e898e8, #de6ede);
    background-image: linear-gradient(#e898e8, #de6ede);
    border-color: #d547d5;
}

.type-curse {
    background: #68a090;
    background-image: -webkit-linear-gradient(#68a090, #4e7c6f);
    background-image: linear-gradient(#68a090, #4e7c6f);
    border-color: #41685d;
}


.type-special {
    background: #4F5870;
    font-family: verdana, sans-serif;
    font-size: 10px;
}

.type-physical {
    background: #C92112;
    font-family: verdana, sans-serif;
    font-size: 10px;
}

.type-status {
    background: #8C888C;
    font-family: verdana, sans-serif;
    font-size: 10px;
}

.pokemar-card{
    background-color: #1e2953;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.attack-damage{
    position: absolute;
    top: 30%;
    right: 25%;
    animation: attck 1s ;
}

.win-class{
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    z-index: 100;
}
.win-class div{
   padding: 20px;
    border: 5px solid green;
    height: 83px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 47px;
    font-weight: 800;
    border-radius: 20px;
}
.lose-class{
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    z-index: 100;
}
.lose-class div{
    padding: 20px;
    border: 5px solid red;
    height: 83px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 47px;
    font-weight: 800;
    border-radius: 20px;
}
.border-bottom-theme{
    padding: 10px;
    border-bottom: 2px solid #ababac;
}
.border-bottom-theme span{
    color: #ababac;
}
.menu-container{
    backdrop-filter: blur(2px);
    padding: 30px !important;
}
.responsive-menu-li{
    display: block;
}
.responsive-menu{
    display: none !important;
}
.opp-attacking{
    position: absolute;
    right: 50%;
    transform: translate(0px,-50%);
    top: 0px;
}

@keyframes attck {
    from{
        top:30%;
    }
    to{
        top:-30%
    }
}